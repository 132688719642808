<template>
    <div class="row">
        <div class="top_nav">
            <div class="nav toggle">
                <a id="menu_toggle"><i class="fa fa-bars" @click="changeNavSize()"><font-awesome-icon icon="bars" /></i></a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data: function () {
        return {
        };
    },
    methods: {
        changeNavSize() {
            if (this.$store.state.topNaviState === 'large') {
                this.$store.dispatch('setTopNaviState', 'small')
            } else {
                this.$store.dispatch('setTopNaviState', 'large')
            }
        },
    },
    mounted() {
    },
};
</script>