<template>
    <div :class="[$store.state.topNaviClass, {'d-none': !isLogin}]">
        <div class="container body">
            <div class="main_container">
                <!-- sidebar menu -->
                <Sidebar />
                <!-- /sidebar menu -->

                <!-- top navigation -->
                <TopNavigation />
                <!-- /top navigation -->
                <!-- page content -->
                <div class="row">
                    <div class="col-md-10 col-sm-10 right_col">
                        <div class="x_panel">
                            <div class="x_title">
                                <h2 v-if="channel.name == ''">ライブ配信管理</h2>
                                <h2 v-else>ライブ配信管理 ― {{ channel.name }}</h2>
                                <div class="clearfix"></div>
                            </div>
                            <div class="card">
                                <div class="card-header">ライブ配信基本情報</div>
                                <div class="card-body">
                                    <div class="x_content">
                                        <div v-if="isLoading()">
                                            <Loading />
                                        </div>
                                        <dl v-else class="row mb-0">
                                            <dt class="col-md-2 font-weight-normal">ライブ配信設定名</dt>
                                            <dd class="col-md-10">
                                                {{ channel.name }}
                                            </dd>

                                            <dt class="col-md-2 font-weight-normal">メイン<br>打ち上げ先情報</dt>
                                            <dd class="col-md-10">
                                                <div class="row">
                                                    <div class="col-md-10">
                                                        RTMP URL：{{ medialive.main_rtmp_url }}<br>
                                                        ストリームキー：{{ medialive.main_stream_key }}
                                                    </div>
                                                    <div class="col-md-1">
                                                        <span class="d-inline-block" tabindex="0" id="mainCopy" data-toggle="tooltip" data-trigger="manual" data-placement="right" title="コピーしました">
                                                            <button type="button" class="btn btn-secondary btn-sm copyButton" @click="copy('main', medialive.main_rtmp_url)">コピー</button>
                                                        </span>
                                                    </div>
                                                </div>
                                            </dd>

                                            <dt class="col-md-2 font-weight-normal">バックアップ<br>打ち上げ先情報</dt>
                                            <dd class="col-md-10 mb-0">
                                                <div class="row">
                                                    <div class="col-md-10">
                                                        RTMP URL：{{ medialive.backup_rtmp_url }}<br>
                                                        ストリームキー：{{ medialive.backup_stream_key }}
                                                    </div>
                                                    <div class="col-md-1">
                                                        <span class="d-inline-block" tabindex="0" id="backupCopy" data-toggle="tooltip" data-trigger="manual" data-placement="right" title="コピーしました">
                                                            <button type="button" class="btn btn-secondary btn-sm copyButton" @click="copy('backup', medialive.backup_rtmp_url)">コピー</button>
                                                        </span>
                                                    </div>
                                                </div>
                                            </dd>
                                        </dl>
                                    </div>
                                </div>
                            </div>

                            <div class="card mt-3">
                                <div class="card-header">
                                    広告管理
                                </div>
                                <div class="card-body">
                                    <div class="x_content">
                                        <div v-if="isLoading()">
                                            <Loading />
                                        </div>
                                        <div v-else>
                                            <p>VASTタグは</p>
                                            <p class="text-break">{{ vast.url }}</p>
                                            <p>で登録されています。</p>

                                            <div class="text-right">
                                                <button type="button" class="btn btn-primary" :disabled="isLoading()" @click="onVast()">編集</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="card mt-3">
                                <div class="card-header">
                                    ライブ配信コントロール
                                </div>
                                <div class="card-body">
                                    <div class="x_content">
                                        <div v-if="isLoading()">
                                            <Loading />
                                        </div>
                                        <div v-else>
                                            <p :class="{'text-danger': medialive.state == 'RUNNING'}">
                                                現在{{ medialive.state_message }}
                                            </p>
                                            <div class="text-right">
                                                <router-link :to="{name :'live-stream', params: {
                                                    channel_id: channel.id
                                                }}" class="btn btn-primary" target="_blank">パネル表示</router-link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            

                            <div class="card mt-3">
                                <div class="card-header">ログ管理</div>
                                <div class="card-body">
                                    <div class="x_content">
                                        <div v-if="isLoading()">
                                            <Loading />
                                        </div>
                                        <div v-else>
                                            <div class="text-right">
                                                <button type="button" class="btn btn-primary" :disabled="isLoading()" @click="onClickToLog()">詳細</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- /page content -->
            </div>
        </div>

        <Modal id="errorModal" type="close" :message="error_message" />
    </div>
</template>

<script>
import Mixin from "../mixins/utility.js";
import api from '@/modules/api';
import Sidebar from '@/components/Sidebar.vue';
import TopNavigation from '@/components/TopNavigation.vue';
import Loading from '@/components/Loading.vue';
import Modal from '@/components/Modal.vue';
import $ from 'jquery';

export default {
    mixins: [Mixin],
    props: {
    },
    components: {
        Sidebar,
        TopNavigation,
        Loading,
        Modal
    },
    data() {
        return {
            isLoaded: false,
            error_message: '',
            live_stream_panel_url: '',

            channel: {
                id: '',
                name: '',
            },
            vast: {
                id: '',
                url: ''
            },
            medialive: {
                main_rtmp_url: '',
                main_stream_key: '',
            }
        };
    },
    methods: {
        isLoading() {
            return !this.isLoaded;
        },

        copy(type, text) {
            navigator.clipboard.writeText(text).then(() => {
                if (type == 'main') {
                    $('#mainCopy').tooltip('show');
                    setTimeout(() => {
                        $('#mainCopy').tooltip('hide');
                    }, 2000);
                } else {
                    $('#backupCopy').tooltip('show');
                    setTimeout(() => {
                        $('#backupCopy').tooltip('hide');
                    }, 2000);
                }
            });
        },

        onVast() {
            this.$router.push({name :'vast-edit', params: {
                channel_id: this.channel.id,
                vast_id: this.vast.id
            }});
        },

        onClickToLiveStream() {
            this.$router.push({name :'live-stream', params: {
                channel_id: this.channel.id
            }});
        },

        onClickToLog() {
            this.$router.push({name :'log-detail', params: {
                channel_id: this.channel.id
            }});
        }
    },
    mounted() {
        this.channel.id = this.$route.params.channel_id;

        this.getUserInfo().then(() => {
            api.getChannelDetail({channel_id: this.channel.id}).then(response => {
                if (response.data.status == 'success') {
                    this.channel = response.data.channel;
                    this.vast = response.data.channel.vast;
                    this.medialive = response.data.medialive;
                } else {
                    this.error_message = response.data.errors.channel_id;
                    $('#errorModal').modal('show');
                }

                this.isLoaded = true;
            })
        }).catch(() => {
            this.$router.push({name :'login'});
        });
    },
    beforeUnmount() {
        $('#mainCopy').tooltip('hide');
        $('#backupCopy').tooltip('hide');
    }
};
</script>

<style scoped>
.copyButton {
    width: 70px;
    height: 38px;
}
</style>