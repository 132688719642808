import axios from "axios";

class Api {
    constructor() {
        this.API_DOMAIN = process.env.VUE_APP_API_DOMAIN;
    }

    request(params) {
        const requestSetting = {
            method: params.method,
            url: params.url
        };

        if (params.data) {
            requestSetting.data = params.data;
        }
        if (params.responseType) {
            requestSetting.responseType = params.responseType;
        }

        return axios(requestSetting).then(response => {
            return Promise.resolve(response);
        }).catch(error => {
            return Promise.reject(error.response.status);
        });
    }

    getUserInfo() {
        return this.request({
            method: 'get',
            url: `${this.API_DOMAIN}/api/user/info`
        });
    }

    login(data) {
        return this.request({
            method: 'post',
            url: `${this.API_DOMAIN}/api/login`,
            data: data
        });
    }

    logout(data) {
        return this.request({
            method: 'post',
            url: `${this.API_DOMAIN}/api/logout`,
            data: data
        });
    }

    getChannelDetail(data) {
        return this.request({
            method: 'get',
            url: `${this.API_DOMAIN}/api/channel/detail/${data.channel_id}`
        })
    }

    getVastDetail(data) {
        return this.request({
            method: 'get',
            url: `${this.API_DOMAIN}/api/vast/detail/${data.vast_id}`
        })
    }

    saveVast(data) {
        return this.request({
            method: 'post',
            url: `${this.API_DOMAIN}/api/vast/update`,
            data: data
        })
    }

    startChannel(data) {
        return this.request({
            method: 'post',
            url: `${this.API_DOMAIN}/api/channel/start`,
            data: data
        })
    }

    stopChannel(data) {
        return this.request({
            method: 'post',
            url: `${this.API_DOMAIN}/api/channel/stop`,
            data: data
        })
    }

    openChannel(data) {
        return this.request({
            method: 'post',
            url: `${this.API_DOMAIN}/api/channel/open`,
            data: data
        })
    }

    closeChannel(data) {
        return this.request({
            method: 'post',
            url: `${this.API_DOMAIN}/api/channel/close`,
            data: data
        })
    }

    insartAd(data) {
        return this.request({
            method: 'post',
            url: `${this.API_DOMAIN}/api/channel/adinsert`,
            data: data
        })
    }

    downloadLog(data) {
        return this.request({
            method: 'post',
            url: `${this.API_DOMAIN}/api/log/download`,
            data: data,
            // responseType: 'blob'
        })
    }
}
const api = new Api();
export default api;