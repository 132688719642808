<template>
    <div>
        <div class="login_wrapper">
            <div class="animate form login_form">
                <section class="login_content">
                    <form @submit.prevent="login">
                        <h1>ログイン</h1>
                        <div class="form-group">
                            <input type="text" class="form-control"  placeholder="ID(EMAIL)" v-model="email" />
                        </div>
                        <div class="form-group mb-0">
                            <input :type="inputPasswordType" class="form-control"  placeholder="パスワード" v-model="password" style="padding-right: 32px" />
                            <button type="button" style=" position: absolute; left: 320px; top: 117px;" @click="onPasswordMaskClick" >
                                <font-awesome-icon :icon="iconPasswordClass" size="lg" :style="{ color: 'black' }" />
                            </button>
                        </div>
                        <!-- error -->
                        <p class="text-danger mb-3" v-if="error_msg">{{ error_msg  }}</p>
                        <!-- /error -->
                        <div>
                            <button type="submit" style="display: block; margin: auto" class="btn btn-secondary submit" :disabled="isSending">ログイン</button>
                        </div>
                    </form>
                </section>
            </div>
        </div>
    </div>
</template>

<script>
import Mixin from "../mixins/utility.js";
import api from '@/modules/api';

export default {
  name: 'Login',
  mixins: [Mixin],
  data() {
    return {
        error_msg: "",
        isSending: false
    };
  },
  methods: {
    login() {
      if (!this.email || !this.password) {
        this.error_msg = 'ID(EMAIL)とパスワードは必須です。';
        return;
      }
      this.isSending = true;

      this.error_msg = '';
      api.login({
        email: this.email,
        password: this.password
      }).then(response => {
        if (response.data.status == 'success') {
          this.$router.push({name :'channel'});
        } else {
          this.error_msg = response.data.errors.message;
        }

        this.isSending = false;
      }).catch(() => {
         this.isSending = false;
      })
    }
  }
}
</script>
