import { createRouter, createWebHistory } from 'vue-router'
import Login from '../views/Login.vue'
import Channel from '../views/Channel.vue'
import Vast from '../views/Vast.vue'
import LiveStream from '../views/LiveStream.vue'
import Log from '../views/Log.vue'

const routes = [
  {
    path: '/',
    name: 'login',
    component: Login
  },
  {
    path: '/login',
    name: 'top',
    component: Login
  },
  {
    path: '/channel',
    name: 'channel',
    redirect: '/channel/detail/1'
  },
  {
    path: '/channel/detail/:channel_id',
    name: 'channel-detail',
    component: Channel
  },
  {
    path: '/vast/edit/:channel_id/:vast_id',
    name: 'vast-edit',
    component: Vast
  },
  {
    path: '/live/stream/:channel_id',
    name: 'live-stream',
    component: LiveStream
  },
  {
    path: '/log/detail/:channel_id',
    name: 'log-detail',
    component: Log
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
