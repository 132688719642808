import api from '@/modules/api';

export default {
    data() {
        return {
            navSize: "nav-md",
            isPasswordChecked: true,
            isLoggedIn: false,
        };
    },

    methods: {
        //ナビメニューサイズ変更
        changeNavSize() {
            if (this.navSize === "nav-md") {
                this.navSize = "nav-sm";
            } else {
                this.navSize = "nav-md";
            }
        },

        //【パスワードマスク処理】目アイコンクリック時フラグ切り替え
        onPasswordMaskClick() {
            this.isPasswordChecked = !this.isPasswordChecked;
        },

        getUserInfo() {
            return new Promise((resolve, reject) => {
                api.getUserInfo().then(response => {
                    if (response.data.status == 'success') {
                        this.isLoggedIn = true;
                        resolve(response.data);
                    } else {
                        this.isLoggedIn = false;
                        reject(response.status);
                    }
                }).catch((status) => {
                    this.isLoggedIn = false;
                    reject(status);
                });
            });
        },
    },

    computed: {
        isLogin() {
            return this.isLoggedIn;
        },

        //【パスワードマスク処理】パスワードマスクフラグ
        inputPasswordType() {
            return this.isPasswordChecked ? "password" : "text";
        },
        //【パスワードマスク処理】目のアイコン切り替え（開く）
        iconPasswordClass() {
            return this.isPasswordChecked ? "eye-slash" : "eye";
        },
    },
};