<template>
    <div class="modal fade" tabindex="-1" role="dialog" :id="id">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-body text-center" v-html="message">
                </div>
                <div v-if="type == 'okcancel'" class="modal-footer justify-content-center">
                    <button type="button" class="btn btn-sm btn-secondary" data-dismiss="modal">キャンセル</button>
                    <button type="button" class="btn btn-sm btn-primary" @click="onClick()">OK</button>
                </div>
                <div v-if="type == 'close'" class="modal-footer justify-content-center">
                    <button type="button" class="btn btn-sm btn-secondary" data-dismiss="modal">閉じる</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: ['id', 'message', 'type'],
    components: {
    },
    data() {
        return {
        };
    },
    methods: {
        onClick() {
            this.$emit('clickOk');
        }
    },
    mounted() {
    },
};
</script>

<style scoped>
.modal .btn {
    min-width: 90px;
}
</style>