<template>
    <div :class="[$store.state.topNaviClass, {'d-none': !isLogin}]">
        <div class="container body">
            <div class="main_container">
                <!-- sidebar menu -->
                <Sidebar />
                <!-- /sidebar menu -->

                <!-- top navigation -->
                <TopNavigation />
                <!-- /top navigation -->
                <!-- page content -->
                <div class="row">
                    <div class="col-md-10 col-sm-10 right_col">
                        <div class="x_panel">
                            <div class="x_title">
                                <h2 v-if="channel.name == ''">ライブ配信管理 ― ログ管理</h2>
                                <h2 v-else>ライブ配信管理 ― {{ channel.name }} ― ログ管理</h2>
                                <div class="clearfix"></div>
                            </div>
                            <div class="card">
                                <div class="card-header">条件指定</div>
                                <div class="card-body">
                                    <div class="x_content">
                                        <div class="form-horizontal form-label-right">
                                            <div class="form-group row">
                                                <label class="col-form-label col-md-1 text-right offset-2">開始日時</label>
                                                <div class="col-md-7">
                                                    <input class="date-picker form-control" type="date" max="2099-12-31" id="startDate" v-model="search.start_date" />
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label class="col-form-label col-md-1 text-right offset-2">終了日時</label>
                                                <div class="col-md-7">
                                                    <input class="date-picker form-control" type="date" max="2099-12-31" id="endDate" v-model="search.end_date" />
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label class="col-form-label col-md-1 text-right offset-2">種別</label>
                                                <div class="col-md-7">
                                                    <input type="text" readonly class="form-control-plaintext" value="同時接続者数">
                                                </div>
                                            </div>

                                            <div class="form-group row"  v-if="error_msg">
                                                <div class="col-md-7  offset-3">
                                                    <span class="text-danger">{{ error_msg }}</span>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="ln_solid"></div>
                                        <div class="item form-group text-center">
                                            <button class="btn btn-success" type="button" @click="onCSVDownload()" :disabled="isProcessing()">CSVダウンロード</button>
                                            <button class="btn btn-primary" type="reset" @click="onReset()">クリア</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="mt-3">
                                <button class="btn btn-primary" type="button" @click="onClickToChannel()">戻る</button>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- /page content -->
            </div>
        </div>
    </div>
</template>

<script>
import Mixin from "../mixins/utility.js";
import api from '@/modules/api';
import Sidebar from '@/components/Sidebar.vue';
import TopNavigation from '@/components/TopNavigation.vue';
import 'bootstrap';
import { saveAs } from 'file-saver';
import Encoding from 'encoding-japanese';
import moment from 'moment';

export default {
    mixins: [Mixin],
    props: {
    },
    components: {
        Sidebar,
        TopNavigation
    },
    data() {
        return {
            isLoaded: false,
            isProcessed: false,

            error_msg: '',

            channel: {
                id: '',
                name: ''
            },

            search: {
                start_date: '',
                end_date: ''
            }
        };
    },
    methods: {
        isLoading() {
            return !this.isLoaded;
        },

        isProcessing() {
            return !this.isProcessed;
        },

        onReset() {
            this.search.start_date = '';
            this.search.end_date = '';
        },

        onCSVDownload() {
            const params = {
                channel_id: this.channel.id
            };

            if (!this.search.start_date && !this.search.end_date) {
                this.error_msg = '開始日時、または、終了日時を指定してください。';
                return;
            }
            if (this.search.start_date && !moment(this.search.start_date).isValid()) {
                this.error_msg = '指定された日付が正しくありません。';
                return;
            }
            if (this.search.end_date && !moment(this.search.end_date).isValid()) {
                this.error_msg = '指定された日付が正しくありません。';
                return;
            }
            if (this.search.start_date && this.search.end_date) {
                const ed = moment(this.search.end_date);
                const sd = moment(this.search.start_date);
                
                if (ed.isBefore(sd)) {
                    this.error_msg = '開始日は終了日と同じ、または過去を指定してください。';
                    return;
                }
                if (ed.diff(sd, 'days') > 30) {
                    this.error_msg = '開始日から終了日までの日数は、31日以内で指定してください。';
                    return;
                }
            }

            this.error_msg = '';

            if (this.search.start_date) {
                params.start_date = this.search.start_date;
            }
            if (this.search.end_date) {
                params.end_date = this.search.end_date;
            }

            api.downloadLog(params).then(response => {
                const unicodeList = Encoding.stringToCode(response.data);
                const shiftJisCodeList = Encoding.convert(unicodeList, 'sjis', 'unicode');
                const shiftJisString = new Uint8Array(shiftJisCodeList);

                const fileName =  `log_${this.channel.id}_${moment().format('YYYYMMDDHHmmss')}.csv`;
                let blob = new Blob([shiftJisString], {type: 'text/csv;charset=sjis'})
                saveAs(blob, fileName);
            }).catch(() => {
                this.error_msg = '日時指定の範囲が正しくありません。'
            });
        },

        onClickToChannel() {
            this.$router.push({name :'channel-detail', params: {
                channel_id: this.channel.id
            }});
        }
    },
    mounted() {
        this.channel.id = this.$route.params.channel_id;

        this.getUserInfo().then(() => {
            api.getChannelDetail({channel_id: this.channel.id}).then(response => {
                this.channel = response.data.channel;

                this.isLoaded = true;
                this.isProcessed = true;
            });
        }).catch(() => {
            this.$router.push({name :'login'});
        });
        
    },
};
</script>