import { createStore } from 'vuex'

export default createStore({
  state: {
    topNaviState: 'large',
    topNaviClass: 'nav-md',
  },
  getters: {
  },
  mutations: {
    setTopNaviState(state, payload) {
      state.topNaviState = payload;

      if (payload == 'large') {
        state.topNaviClass = 'nav-md';
      } else {
        state.topNaviClass = 'nav-sm';
      }
    },
  },
  actions: {
    setTopNaviState(context, payload) {
      context.commit('setTopNaviState', payload);
    },
  },
  modules: {
  }
})
