<template>
    <div class="col-md-3 left_col sidebar_fixed" style="position: fixed">
        <div class="left_col scroll-view">
            <div class="clearfix"></div>
            <br />
            <div id="sidebar-menu" class="main_menu_side hidden-print main_menu">
                <div class="menu_section">
                    <h3>&nbsp;</h3>
                    <ul class="nav side-menu">
                        <li>
                            <router-link to="/channel">
                                <font-awesome-icon icon="edit" />ライブ配信管理
                            </router-link>
                        </li>
                        <li>
                            <a @click="logout"><font-awesome-icon icon="sign-out-alt" />ログアウト</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import api from '@/modules/api';

export default {
    data: function () {
        return {
        };
    },
    methods: {
        //ログアウト
        logout() {
            api.logout().then(() => {
                this.$router.push("/login");
            });
        },
    },
    mounted() {
    },
};
</script>