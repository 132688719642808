<template>
    <div :class="[$store.state.topNaviClass, {'d-none': !isLogin}]">
        <div class="container body">
            <div class="main_container liveStreamPanel">
                <!-- page content -->
                <div class="row">
                    <div class="col-12">
                        <div class="x_panel">
                            <div class="x_title">
                                <h2 v-if="channel.name == ''">ライブ配信コントロール</h2>
                                <h2 v-else>ライブ配信コントロール ― {{ channel.name }}</h2>
                                <div class="clearfix"></div>
                            </div>
                            <div class="card">
                                <div class="card-header">ライブ映像モニタリング</div>
                                <div class="card-body">
                                    <div class="x_content">
                                        <div v-if="isLoading()">
                                            <Loading />
                                        </div>
                                        <div v-else>
                                            <div class="row justify-content-md-center">
                                                <div class="col-5">
                                                    <div class="embed-responsive embed-responsive-16by9 videoPlayerWrapper">
                                                        <div class="embed-responsive-item">
                                                            <iframe class="videoPlayer" v-if="isPlayerShow" :src="'/player.html?src=' + channel.stream_url"></iframe>
                                                            <div v-if="!isPlayerShow" class="h-100 d-flex align-items-center justify-content-center">
                                                                <button  class="btn" :class="{'btn-primary': isLiveRunning(), 'btn-secondary': !isLiveRunning()}" :disabled="!isLiveRunning()" data-toggle="modal" data-target="#showPlayerModal">ライブ映像表示</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row justify-content-md-center mt-3 text-center">
                                                <div class="col-3">
                                                    <button type="button" class="btn" :class="{'btn-primary': isLiveEnabled(), 'btn-secondary': !isLiveEnabled()}" :disabled="!isLiveEnabled()" @click="onToggleLiveStream()">
                                                        <span v-if="isLiveStart()">ライブ配信停止</span>
                                                        <span v-else>ライブ配信開始</span>
                                                    </button>
                                                    <p :class="['mt-3', {'text-danger': isLiveRunning()}]">{{ medialive.state_message }}</p>
                                                </div>
                                                <div class="col-3">
                                                    <button type="button" class="btn" :class="{'btn-primary': isPlayerEnabled(), 'btn-secondary': !isPlayerEnabled()}" :disabled="!isPlayerEnabled()" @click="onTogglePlayerFuta()">
                                                        <span v-if="isPlayerOpen()">プレイヤー停止</span>
                                                        <span v-else>プレイヤー起動</span>
                                                    </button>

                                                    <p v-if="isPlayerOpen()" class="mt-3 text-danger">配信映像が流れています。</p>
                                                    <p v-else class="mt-3">蓋画像が表示されています。</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="card mt-3">
                                <div class="card-header">広告挿入</div>
                                <div class="card-body">
                                    <div class="x_content">
                                        <div v-if="isLoading()">
                                            <Loading />
                                        </div>
                                        <div v-else class="row">
                                            <div class="col-6 border-right">
                                                <p class="font-weight-bold">VASTタグ</p>
                                                <dl class="row">
                                                    <dt class="col-2 font-weight-normal">VAST URL：</dt>
                                                    <dd class="col-10 text-break">
                                                        {{ vast.url }}
                                                    </dd>

                                                    <dt class="col-2 font-weight-normal">CM枠秒数：</dt>
                                                    <dd class="col-10">
                                                        {{ vast.time }}
                                                    </dd>
                                                </dl>
                                            </div>
                                            <div class="col-6">
                                                <p class="font-weight-bold">広告挿入ボタン</p>
                                                <div class="text-center">
                                                    <button class="btn rounded-circle adInsertButton" :class="{'btn-success': isAdInsertEnabled(), 'btn-secondary': !isAdInsertEnabled()}" @click="onInsertAd()" :disabled="!isAdInsertEnabled()"> </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <!-- /page content -->
            </div>
        </div>

        <Modal id="showPlayerModal" type="okcancel" @clickOk="onShowPlayer()" message="配信プレビューでも流量は<br>カウントされますが、よろしいですか？" />
    </div>
</template>

<script>
import Mixin from "../mixins/utility.js";
import api from '@/modules/api';
import Modal from '@/components/Modal.vue';
import Loading from '@/components/Loading.vue';
import $ from 'jquery';
import 'bootstrap';

let medialiveTimerId;

export default {
    mixins: [Mixin],
    props: {},
    components: {
        Modal,
        Loading
    },
    data() {
        return {
            isLoaded: false,
            isLiveProcessed: false,
            isPlayerProcessed: false,
            isAdInsertProcessed: false,
            isPlayerShow: false,

            futaStateMessage: '',

            channel: {
                id: '',
                name: '',
                stream_url: '',
            },
            vast: {
                id: '',
                url: ''
            },
            medialive: {
                state: 'IDLE',
                state_message: ''
            },
            player: {
                state: 'before'
            }
        };
    },
    methods: {
        isLoading() {
            return !this.isLoaded;
        },
        isLiveRunning() {
            if (this.medialive.state == 'RUNNING') {
                return true;
            }
            return false;
        },
        isLiveStart() {
            if (this.medialive.state == 'RUNNING' || this.medialive.state == 'STARTING') {
                return true;
            }
            return false;
        },
        isLiveEnabled() {
            if (!this.isLoaded || !this.isLiveProcessed || !this.isPlayerProcessed || this.isPlayerOpen()) {
                return false;
            }
            return true;
        },
        isPlayerEnabled() {
            if (!this.isLoaded || !this.isPlayerProcessed || !this.isLiveProcessed || !this.isLiveRunning()) {
                return false;
            }
            return true;
        },
        isPlayerOpen() {
            return this.player.state == 'open';
        },
        isAdInsertEnabled() {
            if (!this.isAdInsertProcessed || !this.isLiveRunning()) {
                return false;
            }
            return true;
        },

        onShowPlayer() {
            this.isPlayerShow = true;
            $('#showPlayerModal').modal('hide');
        },
        
        onToggleLiveStream() {
            this.isLiveProcessed = false;
            if (this.isLiveStart()) {
                api.stopChannel({channel_id: this.channel.id}).then(response => {
                    this.medialive = response.data.medialive;
                    this.isLiveProcessed = true;

                    this.startCheckLiveState();
                });
            } else {
                api.startChannel({channel_id: this.channel.id}).then(response => {
                    this.medialive = response.data.medialive;
                    this.isLiveProcessed = true;

                    this.startCheckLiveState();
                });
            }
        },

        onTogglePlayerFuta() {
            this.isPlayerProcessed = false;

            if (this.isPlayerOpen()) {
                api.closeChannel({channel_id: this.channel.id}).then(response => {
                    this.player = response.data.player;
                    this.isPlayerProcessed = true;
                });
            } else {
                api.openChannel({channel_id: this.channel.id}).then(response => {
                    this.player = response.data.player;
                    this.isPlayerProcessed = true;
                });
            }
        },

        onInsertAd() {
            this.isAdInsertProcessed = false;
            api.insartAd({channel_id: this.channel.id}).then(() => {
                this.isAdInsertProcessed = true;
            });
        },

        startCheckLiveState() {
            if (medialiveTimerId) {
                clearTimeout(medialiveTimerId);
            }

            medialiveTimerId = setTimeout(() => {
                api.getChannelDetail({channel_id: this.channel.id}).then(response => {
                    if (this.medialive.state != response.data.medialive.state) {
                        this.medialive = response.data.medialive;
                    }

                    if (response.data.medialive.state == 'RUNNING' || response.data.medialive.state == 'IDLE') {
                        clearTimeout(medialiveTimerId);
                        medialiveTimerId = null;
                    } else {
                        this.startCheckLiveState();
                    }
                });
            }, 5000);
        }
    },
    mounted() {
        this.channel.id = this.$route.params.channel_id;

        this.getUserInfo().then(() => {
            api.getChannelDetail({channel_id: this.channel.id}).then(response => {
                this.channel = response.data.channel;
                this.vast = response.data.channel.vast;
                this.medialive = response.data.medialive;
                this.player = response.data.player;

                this.isLoaded = true;
                this.isLiveProcessed = true;
                this.isPlayerProcessed = true;
                this.isAdInsertProcessed = true;

                if (this.medialive.state != 'IDLE' && this.medialive.state != 'RUNNING') {
                    this.startCheckLiveState();
                }
            });
        }).catch(() => {
            this.$router.push({name :'login'});
        });
        
    }
};
</script>

<style scoped>
.liveStreamPanel {
    margin-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
}
.videoPlayerWrapper {
    background-color: #000;
}
.videoPlayer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.adInsertButton {
    width: 80px;
    height: 80px;
}
</style>