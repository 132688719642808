<template>
    <div :class="[$store.state.topNaviClass, {'d-none': !isLogin}]">
        <div class="container body">
            <div class="main_container">
                <!-- sidebar menu -->
                <Sidebar />
                <!-- /sidebar menu -->

                <!-- top navigation -->
                <TopNavigation />
                <!-- /top navigation -->
                <!-- page content -->
                <div class="row">
                    <div class="col-md-10 col-sm-10 right_col">
                        <div class="x_panel">
                            <div class="x_title">
                                <h2 v-if="channel.name == ''">ライブ配信管理 ― 広告管理</h2>
                                <h2 v-else>ライブ配信管理 ― {{ channel.name }} ― 広告管理</h2>
                                <div class="clearfix"></div>
                            </div>
                            <div class="card">
                                <div class="card-header">広告管理</div>
                                <div class="card-body">
                                    <div class="x_content">
                                        <div v-if="isLoading()" class="mb-3">
                                            <Loading />
                                        </div>
                                        <div v-else class="form-horizontal form-label-right">
                                            <div class="form-group d-flex align-items-center">
                                                <label class="vast-form-label">VAST URL<span class="required">*必須</span></label>
                                                <div class="flex-fill">
                                                    <input v-if="errors.vast.url" type="text" class="form-control is-invalid" required v-model="vast.url" />
                                                    <input v-else type="text" class="form-control" required v-model="vast.url" />
                                                    <div class="invalid-feedback">{{ this.errors.vast.url }}</div>
                                                </div>
                                            </div>
                                            <div class="form-group d-flex align-items-center">
                                                <label class="vast-form-label">CM枠秒数<span class="required">*必須</span></label>
                                                <div class="cmInput">
                                                    <div class="input-group">
                                                        <input v-if="errors.vast.time" type="text" class="form-control is-invalid" required v-model="vast.time" />
                                                        <input v-else type="text" class="form-control" required v-model="vast.time" />
                                                        <div class="input-group-append">
                                                            <span class="input-group-text">秒</span>
                                                        </div>
                                                        <div class="invalid-feedback">{{ this.errors.vast.time }}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="text-center">
                                        <button type="button" class="btn btn-secondary" @click="onCancel()">戻る</button>
                                        <button type="button" class="btn btn-primary" @click="onUpdate()" :disabled="isProcessing()">変更</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- /page content -->
            </div>
        </div>
    </div>
</template>

<script>
import Mixin from "../mixins/utility.js";
import api from '@/modules/api';
import Sidebar from '@/components/Sidebar.vue';
import TopNavigation from '@/components/TopNavigation.vue';
import Loading from '@/components/Loading.vue';
import 'bootstrap';

export default {
    mixins: [Mixin],
    props: {
    },
    components: {
        Sidebar,
        TopNavigation,
        Loading
    },
    data() {
        return {
            isLoaded: false,
            isProcessed: false,

            errors: {
                vast: {
                    url: '',
                    time: ''
                }
            },

            channel: {
                id: '',
                name: ''
            },
            vast: {
                id: '',
                url: '',
                time: ''
            },
        };
    },
    methods: {
        isLoading() {
            return !this.isLoaded;
        },

        isProcessing() {
            return !this.isProcessed;
        },

        onCancel() {
            this.$router.push({name :'channel-detail', params: {
                channel_id: this.channel.id
            }});
        },
        onUpdate() {
            this.isProcessed = false;
            this.errors.vast = {url: '', time: ''};

            api.saveVast({vast_id: this.vast.id, url: this.vast.url, time: this.vast.time}).then(response => {
                if (response.data.status == 'success') {
                    this.$router.push({name :'channel-detail', params: {
                        channel_id: this.channel.id
                    }});
                } else {
                    Object.keys(response.data.errors).forEach(key => {
                        this.errors.vast[key] = response.data.errors[key];
                    });
                }

                this.isProcessed = true;
            })
        }
    },
    mounted() {
        this.channel.id = this.$route.params.channel_id;
        this.vast.id = this.$route.params.vast_id;

        this.getUserInfo().then(() => {
            api.getVastDetail({vast_id: this.vast.id}).then(response => {
                this.channel = response.data.vast.channel;
                this.vast = response.data.vast;

                this.isLoaded = true;
                this.isProcessed = true;
            });
        }).catch(() => {
            this.$router.push({name :'login'});
        });
    },
};
</script>
<style scoped>
.vast-form-label {
    width: 115px;
}
.vast-form-label .required {
    margin-left: 6px;
    color: #dc3545;
}
.cmInput {
    width: 180px;
}
</style>